:root{
 --google:#545454;
 --googlebk:white;
 --googleShadow: 0 1px 2px 1px #ddd;
}
.btn{
  border-radius: 0.2em;
  padding: 0.6rem 1.2rem;
}

.btn-primary{
  background-color: var(--brick);
  border-color: var(--brick);

  &:hover{
    background-color: var(--brickHover);
    border-color: var(--brickHover);
  }

  &:focus, &.focus {
    background-color: var(--brickHover);
    border-color: var(--brickHover);
    box-shadow: 0 0 0 0.2rem rgba(244, 120, 38, 0.5);
  }
  &.disabled, &:disabled {
    background-color: var(--brickHover);
    border-color: var(--brickHover);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: var(--brickHover);
    border-color: var(--brickHover);
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 96, 38, 0.5);
  }


}

.btn-white{
  color:var(--brick);
  background-color: white;
  border-color: white;

  &:hover{
    color:white;
    background-color: var(--brickHover);
    border-color: var(--brickHover);
  }

  &:focus, &.focus {
    color:white;
    background-color: var(--brickHover);
    border-color: var(--brickHover);
    box-shadow: 0 0 0 0.2rem rgba(244, 120, 38, 0.5);
  }
  &.disabled, &:disabled {
    background-color: var(--google);
    border-color: var(--google);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: var(--brickHover);
    border-color: var(--brickHover);
    color:white;
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 96, 38, 0.5);
  }


}

.btn-blue{
  background-color: var(--brandBlue);
  border-color: var(--brandBlue);

  &:hover{
    background-color: var(--brandBlueHover);
    border-color: var(--brandBlueHover);
    text-decoration: none;
  }

  &:focus, &.focus {
    background-color: var(--brandBlueBright);
    border-color: var(--brandBlueBright);
    box-shadow: 0 0 0 0.2rem rgba(79, 82, 252, 0.5);
    text-decoration: none;

  }
  &.disabled, &:disabled {
    background-color: var(--brandBlueBright);
    border-color: var(--brandBlueBright);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: var(--brandBlueBright);
    border-color: var(--brandBlueBright);
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 82, 252, 0.5);
  }


}

.btn-secondary{
  color:var(--brick);
  background-color: white;
  border-color: white;

  &:hover{
    color:var(--brick);
    background-color: var(--brickSoft);
    border-color: var(--brickSoft);
  }

  &:focus, &.focus {
    color:white;
    background-color: var(--brick);
    border-color: var(--brick);
    box-shadow: 0 0 0 0.2rem rgba(244, 120, 38, 0.5);
  }
  &.disabled, &:disabled {
    background-color: var(--google);
    border-color: var(--google);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: var(--brickHover);
    border-color: var(--brickHover);
    color:white;
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 96, 38, 0.5);
  }


}

.btn-google{
  color:var(--google);
  background-color: var(--googlebk);
  border-color:var(--googlebk);

  &:hover{
    background-color: var(--googlebk);
    border-color:var(--googlebk);
    box-shadow: var(--googleShadow);
    color:var(--google);
  }

  &:focus, &.focus {
    background-color: var(--googlebk);
    border-color:var(--googlebk);
    box-shadow: var(--googleShadow);
  }
  &.disabled, &:disabled {
    background-color: var(--gray);
    border-color: var(--gray);
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: var(--blue);
    border-color: var(--blue);
    color: white;
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: var(--googleShadow);
  }


}



/*Button for dropdown*/
.dropdown-toggle::after {
  display: none;
}

.btn-drop{
  color:var(--gray);
  background-color: white;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid var(--gray);
  justify-content: left;
  display:flex;
  align-items: center;
  cursor: pointer;

  transition: transform 250ms ease-in-out;

  &:hover{
    color:var(--brick);
    border-bottom: 3px solid var(--brick);
  }

  &:focus, &.focus {
    color:white;
    border-color: var(--brick);
    box-shadow: 0 0 0 0.2rem rgba(244, 120, 38, 0.5);
  }
  &.disabled, &:disabled {
    background-color: #e4e4e4;
    border-color: var(--google);
    cursor: not-allowed;
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    border-color: var(--brickHover);
    color:white;
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 96, 38, 0.5);
  }


}

.arrow-toggle{
  margin-left: auto;
}
