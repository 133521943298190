.n-link{

  &:hover{
    text-decoration: none;
  }

  &:focus{
    text-decoration: none;
  }

}