:root{
  --offsetX:0%;
  --offsetY:-150%;
}


.input-msg-caption{
  opacity: 0;
  color:var(--gray);
  transition: opacity 300ms ease-in;
}

@media (min-width:100px){

  .form-input{
    position: relative;
    z-index: 10;
  }
  
  .form-input{
    color:var(--gray);
    border-radius: 0;
    border-style: none;
    
    & .form-control:focus{
      border-bottom: 3px solid var(--brick);
    }
 
  }
  
  .form-control{
    border-style: none;
    border-radius: 0;
    border-bottom: 1px solid #c7c7c7;

    /* If input is not empty */
    &:not(:placeholder-shown){
      /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
      
      border-bottom: 3px solid var(--brick);

      &  ~ .input-label{
        transform: scale(0.5);
        transform: translate(var(--offsetX),var(--offsetY)); 
        color:rgb(163, 163, 163);
      }
    }

    


  }

  .input-label{
    transition: transform 100ms ease-in-out;
  }
  

}




@media (min-width:768px){

  .form-input{
    position: relative;
    z-index: 10;
  }
  
  .input-label{
    position: absolute;
    z-index: 1;
  }

  
  .form-input{
    color: var(--gray);
    border-radius: 0;
    border-style: none;

    &:after{
      display:block;
      content: '';
      border-bottom: 3px solid var(--gray);  
      transform: scaleX(0);  
      transition: transform 250ms ease-in-out;
    }
  
    &:hover:after{ 
      transform: scaleX(1); 
    }

    &:hover + .form-control-message .input-msg-caption{
      opacity:1;
    }
    
    & .form-control:focus{
      border-bottom: 3px solid var(--brick);
    }

    & .form-control:focus ~ .input-label{
      transform: scale(0.5);
      transform: translate(var(--offsetX),var(--offsetY)); 
      color:rgb(163, 163, 163)
    }

  
  }
  
  .form-control{
    border-style: none;
    border-radius: 0;
    border-bottom: 1px solid #c7c7c7;

    /* If input is not empty */
    &:not(:placeholder-shown) ~ .input-label{
      transform: scale(0.5);
      transform: translate(var(--offsetX),var(--offsetY)); 
      color:rgb(163, 163, 163)
    }


  }

  .input-label{
    transition: transform 250ms ease-in-out;
  }
  

}



