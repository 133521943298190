.card-icons{

  &:hover{
    color: var(--brick);
  }

}

.newPIcon{
  
  &:hover{

    & .card-body{
      background-color: var(--brick);
      color: white;
    }

    & .card-body-text{
      color: white;
    }
  }



}