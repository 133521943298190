@font-face {
  font-family: "GelionRegular";
  src: local("GelionRegular"),
    url(./public/Fonts/Gelion/Gelion-Regular.ttf) format("TrueType");
}
@font-face {
  font-family: "GelionLight";
  src: local("GelionLight"),
    url(./public/Fonts/Gelion/Gelion-Light.ttf) format("TrueType");
}
@font-face {
  font-family: "GelionBold";
  src: local("GelionBold"),
    url(./public/Fonts/Gelion/Gelion-Bold.ttf) format("TrueType");
}

body {
  margin: 0%;
  background-color: #f5f5f5;
  font-family: GelionRegular, GelionLight, GelionBold;
}

.itemMenu {
  border-bottom: .3rem solid #ee544000;
  transition: opacity 250ms ease-in-out;
}

.itemMenuSelected {
  border-bottom: .3rem solid #ee5340;
}

.item {
  border: 1px solid;
  border-color: transparent transparent #e2e2e2 transparent;
}

.itemMenu:hover {
  border-bottom: .3rem solid #ee5340;
}

.newPIcon span {
  font-size: 7rem;
  color: #ee5340;
}
.newPIcon:hover span {
  color: #ffffff;
}
.newPIcon:hover {
  color: #ffffff;
}

@media only screen and (min-width: 100px) {
  .newPIcon span {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 960px) {
  .newPIcon span {
    font-size: 7rem;
  }
}
